



















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import FolderNavigation from '@/components/management/FolderNavigation.vue';
import PaginationDropdown from '@/components/common/PaginationDropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { PaginationDetails } from '@/models/generic';
@Component({
  components: { FolderNavigation, PaginationDropdown },
})
export default class ManagementLayout extends Vue {
  //UI State
  itemsPerPage = 10;
  currentPage = 1;
  isCurrentPageReset = false;
  isPageRequested = false;

  @Prop({
    default: 10,
  })
  totalCount!: number;

  @Prop()
  resetPagination!: boolean;

  @Prop()
  currentLimit!: number;

  @Prop()
  specifiedPage!: number;

  @Prop()
  isPageSpecified!: boolean;

  @Watch(`specifiedPage`)
  @Watch(`isPageSpecified`)
  updateCurrentPage() {
    this.isPageRequested = this.isPageSpecified;
    if (this.isPageRequested) {
      this.currentPage = this.specifiedPage;
      this.isPageRequested = false;
      this.resetCurrentPageStatus();
    }
  }

  @Watch(`currentLimit`)
  resetItemsPerPage() {
    this.itemsPerPage = this.currentLimit;
  }

  @Watch(`resetPagination`)
  resetCurrentPagination() {
    this.isCurrentPageReset = this.resetPagination;
    if (this.isCurrentPageReset) {
      this.currentPage = 1;
      this.isCurrentPageReset = false;
      this.resetPaginationStatus();
    }
  }

  updateItemPerPage(item: DropdownItem) {
    this.itemsPerPage = Number(item.value);
    this.currentPage = 1;
    this.updatePagination();
  }

  @Emit(`resetPagination`)
  resetPaginationStatus() {
    return false;
  }

  @Emit(`resetCurrentPage`)
  resetCurrentPageStatus() {
    return false;
  }

  @Emit(`paginate`)
  updatePagination() {
    const payload: PaginationDetails = {
      page: this.currentPage,
      limit: this.itemsPerPage,
    };
    return payload;
  }
}
