























import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdown';
import { PAGINATION_LIMITS } from '@/utils/constants';
@Component({})
export default class PaginationDropdown extends Vue {
  data: DropdownItem[] = [];
  displayText = ``;
  selectedValue = ``;
  isDropdownReset = false;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop()
  isResetRequested!: boolean;

  @Prop()
  specifiedValue!: number;

  isDropdownShown = false;

  @Watch(`specifiedValue`)
  updateDropdown() {
    if (this.specifiedValue > 0) {
      for (let index = 0; index < this.data.length; index++) {
        if (this.data[index].value === this.specifiedValue.toString()) {
          this.displayText = this.data[index].text;
          this.selectedValue = this.data[index].value;
        }
      }
    }
  }

  created() {
    PAGINATION_LIMITS.forEach((item) => {
      this.data.push({
        text: item.toString(),
        value: item.toString(),
        uniqueKey: `item_${item}`,
      });
    });
    this.displayText = this.data[0].text;
    this.selectedValue = this.data[0].value;
  }

  updateSelectedState(selectedItem: DropdownItem) {
    this.displayText = selectedItem.text;
    this.selectedValue = selectedItem.value;
    this.updateSelectedItem(selectedItem);
  }

  @Watch(`isResetRequested`)
  forceResetPagination() {
    this.isDropdownReset = this.isResetRequested;
    if (this.isDropdownReset) {
      this.displayText = this.data[0].text;
      this.selectedValue = this.data[0].value;
      this.isDropdownShown = false;
      this.isDropdownReset = false;
    }
  }

  @Emit(`select`)
  updateSelectedItem(selectedItem: DropdownItem) {
    this.isDropdownShown = false;
    return selectedItem;
  }
}
