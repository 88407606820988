













import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { FolderItem } from '@/models/miscellaneous';
@Component({})
export default class FolderNavigation extends Vue {
  @Prop({ required: true })
  folderNames!: FolderItem[];

  get folders() {
    return this.folderNames;
  }

  @Emit(`reset`)
  resetFolders(index: number) {
    return this.folderNames.slice(0, index + 1);
  }
}
